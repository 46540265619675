import { types, getRoot, Instance } from 'mobx-state-tree'
import { RootStoreInterface } from '../stores/root_store'

const StickyModel = types
  .model('StickyModel', {
    id: types.identifier,
    name: types.string,
    description: types.string,
    width: 50,
    height: 50,
    color: '#FF0000',
  })
  .views(self => ({
    get isDefault() {
      const root: RootStoreInterface = getRoot(self)
      const board = root.boardData.board
      return board && board.defaultStickyModel.id === self.id
    },
  }))
  .actions(self => ({
    setName(newName: string) {
      self.name = newName
    },
  }))

export type StickyModelType = Instance<typeof StickyModel>

export default StickyModel
