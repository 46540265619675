import { types } from 'mobx-state-tree'

export const Board = types
  .model('Board', {
    id: types.identifier,
    name: types.string,
  })
  .actions(self => ({
    setName(newName: string) {
      self.name = newName
    },
  }))

export type BoardType = typeof Board.Type

export default Board
