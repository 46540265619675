// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/Users/jpb/code/clients/dan/okkro/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-board-tsx": () => import("/Users/jpb/code/clients/dan/okkro/src/pages/board.tsx" /* webpackChunkName: "component---src-pages-board-tsx" */),
  "component---src-pages-boards-tsx": () => import("/Users/jpb/code/clients/dan/okkro/src/pages/boards.tsx" /* webpackChunkName: "component---src-pages-boards-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/jpb/code/clients/dan/okkro/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

