import { types, getEnv, onAction, ISerializedActionCall, IJsonPatch, SnapshotOut } from 'mobx-state-tree'

import { BoardStore } from './board_store'
import BoardDataStore from './board_data_store'
import { Session } from './session_store'
import { History } from './history_store'
import { BoardType } from '../models/board'
import { StickyType } from '../models/sticky'
import { BoardDataType } from '../models/board_data'
import { StickyModelType } from '../models/sticky_model'

export const RootStore = types
  .model('RootStore', {
    boardStore: types.optional(BoardStore, {
      boards: {},
    }),
    boardDataStore: types.optional(BoardDataStore, {}),
    session: types.optional(Session, {}),
    history: types.optional(History, {}),
  })
  .views(self => ({
    get baseURL() {
      return getEnv(self).baseURL
    },
    get fetch() {
      return getEnv(self).fetch
    },
    get boards() {
      return self.boardStore.boards
    },
    get boardData() {
      return self.boardDataStore
    },
  }))
  .actions(self => ({
    afterCreate() {
      onAction(self, call => {
        if (self.history) self.history.addAction(call)
      })
    },
  }))

// export type RootStoreType = Instance<typeof RootStore>
// export type RootStoreType = typeof RootStore.Type

// Prevent Circular type def in children that need to fetch...
export interface RootStoreInterface {
  baseURL: string
  history: {
    actions: Object[]
    patches: Object[]
    snapshots: Object[]
    addAction(action: ISerializedActionCall): void
    addPatch(patch: IJsonPatch): void
    addSnapshot(snapshot: SnapshotOut<BoardDataType>): void
  }
  session: {
    currentBoard: BoardType
    isBoardExpanded: boolean
    selectedStickies: (StickyType | undefined)[]
    editedSticky: StickyType
    shouldDisplayPositions: false
    shouldDisplayGrid: false
    isDebugEnabled: boolean
    compressBoard(): void
    expandBoard(): void
    addStickyToSelection(sticky: StickyType): void
    selectSticky(sticky: StickyType): void
    removeStickyFromSelection(sticky: StickyType): void
    clearStickiesSelection(): void
    editSticky(sticky: StickyType): void
    clearStickyEdition(): void
  }
  boardData: {
    boardId?: string
    loading: boolean
    error?: string
    board?: {
      id: string
      name: string
      defaultStickyModel: StickyModelType
      stickyModels: StickyModelType[]
      stickies: StickyType[]
    }
  }
  fetch(url: string): any
}
