import { types, getRoot, flow, cast, SnapshotOrInstance } from 'mobx-state-tree'

import BoardData from '../models/board_data'
import { RootStoreInterface } from './root_store'

export const BoardDataStore = types
  .model('BoardDataStore', {
    boardId: types.maybe(types.string),
    loading: true,
    error: types.maybe(types.string),
    board: types.maybe(BoardData),
  })
  .actions(self => {
    function markLoading(loading: boolean) {
      self.loading = loading
    }

    function markError(error: string | undefined) {
      self.error = error
    }

    function updateBoard(json: SnapshotOrInstance<typeof BoardData>) {
      self.board = cast(json)
    }

    const loadBoard = flow(function* loadBoards(id: string) {
      if (self.boardId !== id) {
        self.boardId = id
        try {
          const root: RootStoreInterface = getRoot(self)
          markLoading(true)
          markError(undefined)
          if (root.fetch) {
            const json = yield root.fetch(`${root.baseURL}/board.json`)
            updateBoard(json)
            markLoading(false)
          }
        } catch (err) {
          markError(`Failed to load boards (${err})`)
          markLoading(false)
        }
      }
    })

    return {
      updateBoard,
      loadBoard,
    }
  })

export default BoardDataStore
