import { types, Instance } from 'mobx-state-tree'

import Sticky, { StickyType } from '../models/sticky'
import Board from '../models/board'

export const Session = types
  .model('Session', {
    userAgent: '',
    isMobile: false,
    isTablet: false,
    currentBoard: types.safeReference(Board),
    editedSticky: types.safeReference(Sticky),
    selectedStickies: types.array(types.safeReference(Sticky)),
    isBoardExpanded: false,
    shouldDisplayPositions: false,
    shouldDisplayGrid: false,
    isDebugEnabled: false,
    areFontsLoaded: false,
  })
  .actions(self => ({
    setCurrentBoard(board: Instance<typeof Board> | undefined) {
      self.currentBoard = board
      if (!board) {
        this.clearStickiesSelection()
        // FIXME: Do some stuff (like load stickies from board)
      }
    },
    unsetCurrentBoard() {
      this.setCurrentBoard(undefined)
    },
    addStickyToSelection(sticky: StickyType) {
      if (!self.selectedStickies.includes(sticky)) self.selectedStickies.push(sticky)
    },
    removeStickyFromSelection(sticky: StickyType) {
      self.selectedStickies.splice(self.selectedStickies.findIndex(selectedSticky => selectedSticky === sticky), 1)
    },
    clearStickiesSelection() {
      self.selectedStickies.clear()
    },
    editSticky(sticky: StickyType) {
      self.editedSticky = sticky
    },
    clearStickyEdition() {
      self.editedSticky = undefined
    },
    expandBoard() {
      self.isBoardExpanded = true
    },
    compressBoard() {
      self.isBoardExpanded = false
    },
    showPositions() {
      self.shouldDisplayPositions = true
    },
    hidePositions() {
      self.shouldDisplayPositions = false
    },
    toggleDisplayPositions() {
      self.shouldDisplayPositions = !self.shouldDisplayPositions
    },
    toggleDisplayGrid() {
      self.shouldDisplayGrid = !self.shouldDisplayGrid
    },
    toggleDebug() {
      self.isDebugEnabled = !self.isDebugEnabled
    },
    onFontsLoaded(value = true) {
      self.areFontsLoaded = value
    },
  }))
  .actions(self => ({
    selectSticky(sticky: StickyType) {
      self.clearStickiesSelection()
      self.addStickyToSelection(sticky)
    },
  }))

export type SessionType = Instance<typeof Session>
