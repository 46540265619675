import { types, Instance, IJsonPatch, ISerializedActionCall, ModelSnapshotType, SnapshotOut, getRoot } from 'mobx-state-tree'
import { RootStoreInterface } from './root_store'
import { BoardDataType } from '../models/board_data'

export const History = types
  .model('History', {
    actions: types.array(types.map(types.frozen())),
    patches: types.array(types.map(types.frozen())),
    snapshots: types.array(types.map(types.frozen())),
  })
  .views(self => ({
    get shouldDisplayLogs() {
      const root: RootStoreInterface = getRoot(self)
      return root.session.isDebugEnabled
    },
  }))
  .actions(self => ({
    addAction(action: ISerializedActionCall) {
      const logData = { ts: new Date().toISOString(), ...action }
      self.actions.push(logData)
      console.groupCollapsed(`action called: ${logData.name}`)
      console.dir(logData)
      console.groupEnd()
    },
    addPatch(patch: IJsonPatch) {
      const logData = { ts: new Date().toISOString(), ...patch }
      self.patches.push(logData)
      if (self.shouldDisplayLogs) {
        console.groupCollapsed(`patch created`)
        console.dir(logData)
        console.groupEnd()
      }
    },
    addSnapshot(snapshot: SnapshotOut<BoardDataType>) {
      const logData = { ts: new Date().toISOString(), ...snapshot }
      self.snapshots.push(logData)
      if (self.shouldDisplayLogs) {
        console.groupCollapsed(`snapshot created`)
        console.dir(logData)
        console.groupEnd()
      }
    },
  }))

export type HistoryType = Instance<typeof History>
