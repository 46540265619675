import React from 'react'

import 'typeface-roboto'
import 'typeface-caveat-brush'

import WebFont from 'webfontloader'

import { RootStore } from './src/stores/root_store'
import StoreContext from './src/services/store'
import './src/utils/polyfills'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const userAgent = navigator.userAgent
const isMobile = /IEMobile|Windows Phone|Lumia|iPhone|iP[oa]d|Android|BlackBerry|PlayBook|BB10|Mobile Safari|webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(
  userAgent,
)
const isTablet = /Tablet|iPad/i.test(userAgent)

const createStore = () => {
  const fetcher = url => window.fetch(url).then(response => response.json())
  return RootStore.create(
    // defaults
    {
      boardStore: {
        boards: {},
      },
      boardDataStore: {},
      session: {
        userAgent,
        isMobile,
        isTablet,
      },
    },
    // env
    {
      baseURL: '',
      fetch: fetcher,
    },
  )
}

const store = createStore()

WebFont.load({
  timeout: 20000,
  custom: {
    families: ['Roboto:n4,n7', 'Caveat Brush'],
  },
  active: () => setTimeout(store.session.onFontsLoaded, 1000),
  inactive: store.session.onFontsLoaded,
})

window.store = store

document.addEventListener('gesturestart', e => {
  e.preventDefault()
})

export const wrapRootElement = ({ element }) => <StoreContext.Provider value={store}>{element}</StoreContext.Provider>
