import { types, getParent, flow } from 'mobx-state-tree'

import Board from '../models/board'

export const BoardStore = types
  .model('BoardStore', {
    loading: true,
    error: types.maybe(types.string),
    boards: types.map(Board),
  })
  .views(self => ({
    get root() {
      return getParent(self)
    },
  }))
  .actions(self => {
    function markLoading(loading) {
      self.loading = loading
    }

    function markError(error) {
      self.error = error
    }

    function updateBoards(json) {
      json.forEach(boardJson => {
        self.boards.put(boardJson)
      })
    }

    const loadBoards = flow(function* loadBoards() {
      try {
        markLoading(true)
        markError(undefined)
        if (self.root.fetch) {
          const json = yield self.root.fetch(`${self.root.baseURL}/boards.json`)
          updateBoards(json)
          markLoading(false)
        }
      } catch (err) {
        markError(`Failed to load boards (${err})`)
        markLoading(false)
      }
    })

    return {
      updateBoards,
      loadBoards,
    }
  })
