import { types, getParent, getRoot, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { RootStoreInterface } from '../stores/root_store'
import { BoardDataType } from './board_data'
import StickyModel, { StickyModelType } from './sticky_model'
import { Omit } from '../utils/types'

const Sticky = types
  .model('Sticky', {
    id: types.identifier,
    model: types.reference(StickyModel),
    x: 0,
    y: 0,
    customWidth: types.maybe(types.number),
    customHeight: types.maybe(types.number),
    rotation: 0,
    content: 'New Sticky',
  })
  .views(self => ({
    get position() {
      const board: BoardDataType = getParent(self, 2)
      return board.sortedStickies.findIndex(e => e.id === self.id) + 1
    },
    get zIndex() {
      const board: BoardDataType = getParent(self, 2)
      return board.stickies.findIndex(e => e.id === self.id) + 1
    },
    get width() {
      return self.customWidth || self.model.width
    },
    get height() {
      return self.customHeight || self.model.height
    },
    get color() {
      return self.model.color
    },
    get isSelected() {
      const root: RootStoreInterface = getRoot(self)
      return root.session.selectedStickies.find(s => (s ? s.id === self.id : false))
    },
    get isEdited() {
      const root: RootStoreInterface = getRoot(self)
      return root.session.editedSticky && root.session.editedSticky.id === self.id
    },
  }))
  .views(self => ({
    get boundingBox() {
      const rotationRad = (self.rotation * Math.PI) / 180
      const res = {
        x: self.x,
        y: self.y,
        width: self.width,
        height: self.height,
        center: {
          x: self.width / 2,
          y: self.height / 2,
        },
      }

      // Handle sticky rotation
      // No matter rotation, x and y are left untouched
      // Compute topLeft and bottomRight from theroical center
      const topLeft = {
        x: -self.width / 2,
        y: self.height / 2,
      }
      const topLeftRot = {
        x: Math.cos(rotationRad) * topLeft.x - Math.sin(rotationRad) * topLeft.y,
        y: Math.sin(rotationRad) * topLeft.x + Math.cos(rotationRad) * topLeft.y,
      }
      const bottomRight = {
        x: self.width / 2,
        y: -self.height / 2,
      }
      const bottomRightRot = {
        x: Math.cos(rotationRad) * bottomRight.x - Math.sin(rotationRad) * bottomRight.y,
        y: Math.sin(rotationRad) * bottomRight.x + Math.cos(rotationRad) * bottomRight.y,
      }

      // Compute new width
      res.width = Math.max(topLeftRot.x, bottomRightRot.x) - Math.min(topLeftRot.x, bottomRightRot.x)
      res.height = Math.max(topLeftRot.y, bottomRightRot.y) - Math.min(topLeftRot.y, bottomRightRot.y)

      // Recompute center
      res.center = {
        x: res.width / 2,
        y: res.height / 2,
      }

      return res
    },
  }))
  .actions(self => ({
    setPosition(x: number, y: number) {
      self.x = x
      self.y = y
    },
    move(dx: number, dy: number) {
      self.x += dx
      self.y += dy
    },
    setContent(newContent: string) {
      self.content = newContent
    },
    setRotation(newRotation: number) {
      self.rotation = newRotation
    },
    setModel(newModel: StickyModelType) {
      self.model = newModel
    },
  }))
  .actions(self => ({
    resize(dWidth: number, dHeight: number, anchor: string, minWidth = 120, minHeight = 80) {
      const rotationRad = (self.rotation * Math.PI) / 180

      let newdWidth = dWidth
      let newdHeight = dHeight
      const grows = {
        x: 1,
        y: 1,
      }

      // Resize sticky
      switch (anchor) {
        case 'n':
          grows.y = -1
          newdHeight *= -1
          break
        case 'nw':
          grows.x = -1
          grows.y = -1
          newdWidth *= -1
          newdHeight *= -1
          break
        case 'ne':
          grows.y = -1
          newdHeight *= -1
          break
        case 's':
          break
        case 'sw':
          grows.x = -1
          newdWidth *= -1
          break
        case 'se':
          break
        case 'w':
          grows.x = -1
          newdWidth *= -1
          break
        case 'e':
          break
        default:
          break
      }

      if (self.width + newdWidth < minWidth) {
        newdWidth = minWidth - self.width
      }

      if (self.height + newdHeight < minHeight) {
        newdHeight = minHeight - self.height
      }

      self.customWidth = self.width + newdWidth
      self.customHeight = self.height + newdHeight

      self.move(
        -newdWidth / 2 + grows.x * (newdWidth / 2) * Math.cos(rotationRad) - grows.y * (newdHeight / 2) * Math.sin(rotationRad),
        -newdHeight / 2 + grows.x * (newdWidth / 2) * Math.sin(rotationRad) + grows.y * (newdHeight / 2) * Math.cos(rotationRad),
      )
    },
  }))

export type StickyType = typeof Sticky.Type
export type StickySnapshotIn = SnapshotIn<typeof Sticky>
export type StickySnapshotOut = SnapshotOut<typeof Sticky>
export type NewStickyAttributes = Omit<StickySnapshotIn, 'id'>

export default Sticky
