import { types, Instance, getRoot, applySnapshot, onPatch, onSnapshot } from 'mobx-state-tree'
import uuidv4 from 'uuid/v4'

import Sticky, { StickyType, NewStickyAttributes } from './sticky'
import StickyModel, { StickyModelType } from './sticky_model'
import { RootStoreInterface } from '../stores/root_store'

export const BoardData = types
  .model('BoardData', {
    id: types.identifier,
    name: types.string,
    defaultStickyModel: types.reference(StickyModel),
    stickyModels: types.array(StickyModel),
    stickies: types.array(Sticky),
  })
  .views(self => ({
    get allStickyModels() {
      return self.stickyModels.filter(m => m !== null)
    },
    findAllStickiesByModelId(modelId: string) {
      return self.stickies.filter(s => s.model.id === modelId)
    },
    findSticky(id: string) {
      return self.stickies.find(s => s.id === id)
    },
    findStickyModel(id: string) {
      return self.stickyModels.find(m => m.id === id)
    },
    get sortedStickies() {
      return self.stickies.slice().sort((a, b) => {
        return a.x === b.x ? a.y - b.y : a.x - b.x
      })
    },
    get selectedStickies() {
      const root: RootStoreInterface = getRoot(self)
      return root.session.selectedStickies as StickyType[]
    },
    get isExpanded() {
      const root: RootStoreInterface = getRoot(self)
      return root.session.isBoardExpanded
    },
  }))
  .views(self => ({
    get selectionUsedStickyModels() {
      const stickiesWithModels = self.selectedStickies.filter(s => s.model !== null)
      return stickiesWithModels.map(sticky => sticky.model)
    },
  }))
  // Board Actions
  .actions(self => ({
    setName(newName: string) {
      self.name = newName
    },
    expand() {
      const root: RootStoreInterface = getRoot(self)
      root.session.expandBoard()
    },
    compress() {
      const root: RootStoreInterface = getRoot(self)
      root.session.compressBoard()
    },
  }))
  // Sticky
  .actions(self => ({
    addSticky(newStickyAttributes: NewStickyAttributes) {
      const { model } = newStickyAttributes
      if (model) {
        const sticky = Sticky.create({ ...newStickyAttributes, id: uuidv4() })
        self.stickies.push(sticky)
        return sticky
      }
    },
    removeSticky(id: string) {
      self.stickies.splice(self.stickies.findIndex(sticky => sticky.id === id), 1)
    },
  }))
  // Sticky (composed actions)
  .actions(self => ({
    cloneSticky(id: string) {
      const sticky = self.stickies.find(s => s.id === id)
      if (sticky) {
        const { id: _id, ...newStickyAttributes } = sticky
        return self.addSticky({
          ...newStickyAttributes,
          model: newStickyAttributes.model.id,
          x: newStickyAttributes.x + 10,
          y: newStickyAttributes.y + 10,
        })
      }
    },
  }))
  // Select / Unselect
  .actions(self => ({
    selectSticky(id: string) {
      const root: RootStoreInterface = getRoot(self)
      const sticky = self.findSticky(id)
      if (sticky && !root.session.selectedStickies.includes(sticky)) root.session.selectSticky(sticky)
    },
    addStickyToSelection(id: string) {
      const root: RootStoreInterface = getRoot(self)
      const sticky = self.findSticky(id)
      if (sticky) root.session.addStickyToSelection(sticky)
    },
    removeStickyFromSelection(id: string) {
      const root: RootStoreInterface = getRoot(self)
      const sticky = self.findSticky(id)
      if (sticky) root.session.removeStickyFromSelection(sticky)
    },
    addOrRemoveStickyToSelection(id: string) {
      const root: RootStoreInterface = getRoot(self)
      const sticky = self.findSticky(id)
      if (sticky) {
        root.session.selectedStickies.includes(sticky)
          ? root.session.removeStickyFromSelection(sticky)
          : root.session.addStickyToSelection(sticky)
      }
    },
    clearStickiesSelection() {
      const root: RootStoreInterface = getRoot(self)
      root.session.clearStickiesSelection()
    },
  }))
  // Edit content edition
  .actions(self => ({
    editSticky(id: string) {
      const root: RootStoreInterface = getRoot(self)
      const sticky = self.findSticky(id)
      if (sticky) root.session.editSticky(sticky)
    },
    clearStickyEdition() {
      const root: RootStoreInterface = getRoot(self)
      root.session.clearStickyEdition()
    },
  }))
  // Multiple edition (on selection)
  .actions(self => ({
    cloneSelectedStickies() {
      if (self.selectedStickies.length > 0) {
        self.selectedStickies.forEach(s => {
          if (s) {
            const newSticky = self.cloneSticky(s.id)
            if (newSticky) {
              self.removeStickyFromSelection(s.id)
              self.addStickyToSelection(newSticky.id)
            }
          }
        })
      }
    },
    moveSelectedStickies(dx: number, dy: number) {
      if (self.selectedStickies) {
        self.selectedStickies.forEach(sticky => {
          if (sticky) sticky.move(dx, dy)
        })
      }
    },
    removeSelectedStickies() {
      const root: RootStoreInterface = getRoot(self)
      root.session.selectedStickies.forEach(s => s && self.removeSticky(s.id))
    },
    // FIXME: setSelectedStickiesRotation => rotateSelectedStickies
    setSelectedStickiesRotation(newRotation: number) {
      if (self.selectedStickies) {
        self.selectedStickies.forEach(sticky => {
          if (sticky) sticky.setRotation(newRotation)
        })
      }
    },
    setSelectedStickiesModel(newStickyModel: StickyModelType) {
      if (self.selectedStickies) {
        self.selectedStickies.forEach(sticky => {
          if (sticky) sticky.setModel(newStickyModel)
        })
      }
    },
  }))
  // Multiple edition (composed actions)
  .actions(self => ({
    selectAndMoveSelectedStickies(id: string, dx: number, dy: number) {
      self.selectSticky(id)
      self.moveSelectedStickies(dx, dy)
    },
  }))
  // Sticky Model
  .actions(self => ({
    addStickyModel(newStickyModelAttributes: { name: string; description: string; width: number; height: number; color: string }) {
      const model = StickyModel.create({ ...newStickyModelAttributes, id: uuidv4() })
      self.stickyModels.push(model)
      return model
    },
    editStickyModel(stickyModelAttributes: {
      id: string
      name: string
      description: string
      width: number
      height: number
      color: string
    }) {
      const model = self.findStickyModel(stickyModelAttributes.id)
      if (model) applySnapshot(model, stickyModelAttributes)
      return model
    },
    removeStickyModel(id: string) {
      if (self.defaultStickyModel.id !== id) {
        self.clearStickiesSelection()
        self.findAllStickiesByModelId(id).forEach(sticky => self.addStickyToSelection(sticky.id))
        self.setSelectedStickiesModel(self.defaultStickyModel)
        if (self.stickyModels) self.stickyModels.splice(self.stickyModels.findIndex(model => model.id === id), 1)
      }
    },
    setDefaultStickyModel(id: string) {
      const model = self.findStickyModel(id)
      if (model) self.defaultStickyModel = model
    },
  }))
  // Logging
  .actions(self => ({
    afterCreate() {
      const root: RootStoreInterface = getRoot(self)
      onSnapshot(self, snapshot => root.history.addSnapshot(snapshot))
      onPatch(self, patch => root.history.addPatch(patch))
    },
  }))

export type BoardDataType = Instance<typeof BoardData>

export default BoardData
