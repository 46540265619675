import React from 'react'

import { RootStore } from '../stores/root_store'

export const createStore = () => {
  // const fetcher = (url: string) => window.fetch(url).then(response => response.json())
  const fetcher = (_url: string) => null
  return RootStore.create(
    // defaults
    {
      boardStore: {
        boards: {},
      },
      boardDataStore: {},
      session: {},
    },
    // env
    {
      baseURL: '',
      fetch: fetcher,
    },
  )
}

const StoreContext = React.createContext(createStore())

export default StoreContext
